<template>
  <div class="warp">
    <van-tabs v-model:active="active" class="warp-tabs">
      <van-tab title="缺货" name="quehuoxinxi"/>
      <van-tab title="库存" name="stockList"/>
      <van-tab title="格口滞留" name="retentionList"/>
      <van-tab title="断货" name="outOfStockList"/>
      <van-tab title="销售数据" name="4"/>
      <van-tab title="有货格口" name="5"/>
    </van-tabs>

    <div class="datas-box">
      <div class="tips" v-if="active == 'quehuoxinxi' && tableData">
        <span>共缺货 {{ tableData[active].length }} 个</span>
        <span>缺货量由高到低排列</span>
      </div>
      <div>
        <div v-for="(item, index) in tableData[active]" :key="index" class="item">
          <van-image :src="item.pic" class="img-url"/>
          <div class="name-warp">
            <div class="name">
              {{ item.prodName }}
            </div>
            <div class="last-time" v-if="active != 'quehuoxinxi'">
              最后补货时间：{{ item.repairTime }}
            </div>
            <div class="count-infos">
              <div v-if="active == 'quehuoxinxi'">缺货 <span class="_F4730C">{{ item.outOfStock }}</span></div>
              <div v-if="active == 'quehuoxinxi' || active == 'stockList'">当前库存 <span class="_F4730C">{{ item.stock }}</span></div>
              <div v-if="active == 'retentionList'">格口号 <span class="_F4730C">{{ item.idx || '接口未返回' }}</span></div>
              <div v-if="active == 'retentionList' && item.curCt != 0">已经滞留 <span class="_F4730C">{{ item.retentionDate }}天</span></div>
              <div v-if="active == 'outOfStockList'">断货 <span class="_F4730C">{{ item.outOfStock }}个</span></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="active == 4" class="four">
        <div>
          <div v-for="item in dataList" :key="item.id" class="order-info">
            <div class="name">{{ item.address }}</div>
            <div class="time">{{ item.createTime }} 下单</div>
            <div class="infos">
              <span>{{ item.payType == 1 ? '微信支付' : '余额支付' }}</span>
              <span>{{ item.idx }}格口号</span>
              <span>{{ siteOrderStatus[item.status] }}</span>
            </div>
            <div class="name">网点：{{ item.netName }}</div>
            <van-card
                v-for="goodsInfo in item.orderItems" :key="goodsInfo.orderItemId"
                style="background: #f8f7f7"
                :num="goodsInfo.prodCount"
                :price="goodsInfo.price"
                :title="goodsInfo.prodName"
                :thumb="goodsInfo.pic"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="active == 5" class="five">
      <div v-if="channelList.length ==0" style="text-align: center;color: #999;">暂无数据</div>
      <div class="box">
        <van-checkbox-group v-model="checked">
          <van-cell-group inset>
            <div class="idx-warp">
              <div class="idx-box" v-for="(item, index) in channelList" :key="item.idx" :class="item.curCt == 0 ? 'div-disabled' : ''" @click.stop="checkboxRefs[index] = item.idx">
                <div class="up-info">
                  <div class="idx-nums">{{ item.idx }}</div>
                  <div class="checkbox-item">
                    <van-checkbox :name="item.idx" :ref="el => checkboxRefs[index] = el" />
                  </div>
                </div>
                <div class="middle-info">
                  <div class="prod-name">{{ item.prodName }}</div>
                  <van-image :src="item.pic" class="img-url"></van-image>
                  <div class="empty" v-if="item.curCt == 0"></div>
                  <div class="kong" v-if="item.curCt == 0">空</div>
                </div>
              </div>
            </div>
          </van-cell-group>
        </van-checkbox-group>
      </div>
      <div class="btn-box">
        <div class="btn" @click="checkAll">
          <div style="display: flex"><van-checkbox  v-model="checkedAll"/>&nbsp;全选</div>
          <van-button type="primary" size="small" class="btn-two" @click="doClear">清空</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vmcInfos } from "../../assets/api/netPoint";
import { orderList } from "../../assets/api/order";
import {siteOrderStatus} from "../../util/orderStatus";
import {Toast} from "vant";
import {channelInfoList, exchangeRecord} from "../../assets/api/site";

export default {
  data() {
    return {
      siteOrderStatus: siteOrderStatus,
      active: this.$route.query.sign,
      vmcId: this.$route.query.vmcId,
      tableData: {
        'quehuoxinxi': [],
        'stockList': [],
        'retentionList': [],
        'outOfStockList': [],
      },
      dataList: [],
      checked: [],
      checkedAll: false,
      checkboxRefs: [],
      channelList: [],
    }
  },
  mounted() {
    this.getVmcInfo()
    this.getOrderInfos()
    this.initDataList()
  },
  methods: {
    initDataList() {
      const params = {
        vmcId: this.vmcId,
        enable: 1,
        curCt: 1
      }
      channelInfoList(params).then(res => {
        if (res.data.code == 200) {
          this.channelList = res.data.data
        }
      })
    },
    getVmcInfo() {
      vmcInfos({ vmcId: this.vmcId }).then(res => {
        if (res.data.code === 200) {
          this.tableData = res.data.data
        }
      })
    },
    getOrderInfos() {
      const params = {
        vmcId: this.vmcId,
        current: 1,
        size: 1000,
      }
      orderList(params).then( res => {
        if (res.data.code == 200) {
          this.dataList = res.data.data.records
        }
      })
    },
    checkAll() {
      if (this.checked.length == this.channelList.length) {
        this.checked = []
      } else {
        this.channelList.forEach(item => {
          if (this.checked.indexOf(item.idx) < 0) {
            this.checked.push(item.idx)
          }
        })
      }
    },
    doClear() {
      if (this.checked.length == 0) {
        Toast('请选择格口！')
        return
      }
      let params = []
      let checkData = []
      this.channelList.forEach(item => {
        if (this.checked.indexOf(item.idx) > -1) {
          checkData.push({
            idx: item.idx,
            newProductId: item.prodId,
            newProductName: item.prodName
          })
        }
      })
      checkData.forEach(it => {
        const item = {
          vmcId: this.vmcId,
          channelId: it.idx,
          newProductId: it.newProductId,
          newProductName: it.newProductId,
          quantity: 0, // 清空
          type: this.active == 1 ? 2 : 3  // 0：无货补货 ，1：有货补货，2：有货清空，3：无货清空  4:有货换货 5:无货换货
        }
        params.push(item)
      })
      exchangeRecord(params).then(res => {
        if (res.data.code == 200) {
          Toast('清空成功')
          window.location.reload()
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.warp {
  //.warp-tabs {
  //  position: absolute;
  //  height: 20px;
  //  background: #fff;
  //  z-index: 1;
  //}
  .datas-box {
    margin: 10px 15px;
    .tips {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #666;
    }
    .item {
      display: flex;
      padding: 10px 0;
      position: relative;
      border-bottom: 1px solid #dcdee0;

      .img-url {
        width: 50px;
        height: 50px;
        margin-right: 5px;
      }
      .name-warp {
        width: calc(100% - 135px);
        color: #666;
        .name {
          height: 40px;
        }
        .last-time {
          font-size: 12px;
        }
      }

      .count-infos {
        width: 90px;
        position: absolute;
        top: 15px;
        right: 0;
        text-align: right;
        height: 40px;
        line-height: 20px;
      }
    }

    .four {
      .order-info {
        width: calc(100% - 20px);
        color: #666;
        font-size: 12px;
        border: 1px solid #aaaaaa;
        border-radius: 8px;
        margin-top: 15px;
        padding: 5px 10px;
        position: relative;
        background: #f7f8fabd;

        .name {
          font-size: 16px;
          line-height: 18px;
        }
        .time, .infos {
          margin: 4px 0;
          line-height: 18px;
        }
        .infos {
          display: flex;
          span {
            margin-right: 10px;
            border: 1px solid #999999;
            border-radius: 6px;
            padding: 0 6px;
          }
        }
        .van-btn {
          border: none;
          height: 24px;
          line-height: 24px;
          margin-top: 10px;
          margin-right: 20px;
          border-radius: 5px;
        }
      }
    }
  }
  .five {
    margin-bottom: 50px;
    .box {
      width: 100%;
      color: #666;
      font-size: 12px;
      border-radius: 8px;
      margin-top: 15px;
      position: relative;

      .idx-warp {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .idx-box {
          width: calc(50% - 16px);
          border: 1px solid #d7d2d2;
          background: #fff;
          margin-bottom: 10px;
          height: 100px;
          position: relative;
          border-radius: 8px;
          padding: 5px;
          .up-info {
            height: 24px;
            display: flex;
            justify-content: space-between;
            .idx-nums {
              font-size: 20px;
              font-weight: bold;
            }
            .checkbox-item {
              position: absolute;
              right: 4px;
              top: 4px;
            }
          }
          .middle-info {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .prod-name {
              width: calc(100% - 65px);
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;//控制行数
              overflow:hidden;
              height: 32px;
            }
            .empty {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 0px;
              height: 0px;
              border-bottom: 35px solid #333;
              border-left: 40px solid transparent;
              opacity: 0.7;
            }
            .kong {
              position: absolute;
              bottom: 2px;
              right: 6px;
              z-index: 1;
              color: #fff;
            }
            .img-url {
              width: 60px;
              height: 60px;
              margin-top: 8px;
            }
          }
        }
        .div-disabled {
          background: #ffffffe6;
          filter: alpha(Opacity=50);
          -moz-opacity:0.5;
          opacity: 0.5;
        }
      }
    }
    .btn-box {
      position: fixed;
      bottom: 0px;
      width: calc(100% - 20px);
      padding: 5px 10px;
      background: #fff;
      height: 40px;
      .btn {
        display: flex;
        justify-content: space-between;
        height: 28px;
        text-align: right;
        line-height: 28px;
      }
    }
  }
}
</style>
